<template>
  <div class="shadow-md rounded-lg">
    <Accordion
      v-for="(header, index) in headerDayWeather"
      :key="index"
      :class="getBorderClass(index)"
    >
      <template #title>
        <div
          class="grid ss:grid-cols-2 xs:grid-cols-3 justify-items-center gap-5 ss:gap-2.5 sm:gap-0 sm:flex items-center flex-auto"
        >
          <span
            class="ss:row-start-1 ss:row-end-1 ss:col-span-2 xs:col-start-1 xs:col-end-3 sm:flex-1 text-lg font-bold text-blue"
          >
            {{ header.day }}
          </span>
          <div class="xs:row-start-1 xs:row-end-1 xs:col-start-2 xs:col-end-4 sm:flex-1">
            <span class="text-black text-2xl">{{ header.temperature.max }}</span>
            <span class="text-xs"> / {{ header.temperature.min }}</span>
          </div>
          <div
            class="sm:w-40 flex flex-col sm:flex-row items-center sm:space-x-2 sm:flex-auto text-center sm:text-left"
          >
            <icon class="sm:flex-none" :name="`pt-weather-${header.weather.icon}`" type="svg" />
            <span class="text-xs sm:text-sm">{{ header.weather.text }}</span>
          </div>
          <div class="flex items-center sm:flex-1">
            <icon name="pt-weather--3" type="svg" />
            <span class="sm:flex-1">{{ header.precipitation }}</span>
          </div>
          <div
            class="flex flex-col sm:flex-row items-center sm:space-x-2 sm:flex-1 text-center sm:text-left"
          >
            <icon class="sm:flex-none" name="pt-weather-23" type="svg" />
            <span class="sm:flex-1 text-xs">{{ header.wind }}</span>
          </div>
        </div>
      </template>
      <template #content>
        <div class="flex flex-col border border-gray-very-light p-4 rounded">
          <WeatherDayDetail :data="weatherDaysDetail[index]" class="px-2" />
        </div>
        <div class="border-gray-very-light p-4 rounded border mt-2">
          <WeatherDayParts
            :day-parts="weatherDaysParts.daysParts[0]"
            :properties="weatherDaysParts.propertiesPartsDay"
          />
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import Accordion from '@/components/Accordion';
import WeatherDayParts from '@/components/Weather/WeatherDayParts';
import WeatherDayDetail from '@/components/Weather/WeatherDay/WeatherDayDetail';
export default {
  name: 'PtWeatherWeekly',
  components: {
    Accordion,
    WeatherDayParts,
    WeatherDayDetail,
    Icon
  },
  props: {
    weatherDaysParts: {
      type: Object,
      default: () => {}
    },
    weatherDaysDetail: {
      type: Object,
      default: () => {}
    },
    headerDayWeather: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const getBorderClass = index => {
      let style = '';
      if (index === 0) style = 'rounded-t-lg';
      if (index === props.headerDayWeather.length - 1) style = 'rounded-b-lg';

      return style;
    };

    return { getBorderClass };
  }
};
</script>

<style></style>
